import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "public-resources"
    }}>{`Public Resources`}</h1>
    <p>{`There are two way to access resources with rework.js: statically or through webpack`}</p>
    <h2 {...{
      "id": "webpack-resources"
    }}>{`Webpack resources`}</h2>
    <p>{`This is the "standard" way to import resources in rework.js. You do this by using the JavaScript `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement. The url of the resource will be returned (note: that won't download the resource, only give you the url).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "jsx",
        "jsx": true
      }}>{`// my-comp.tsx

import logoUrl from './logo.svg';

export default function Logo() {
  return <img src={logoUrl} />;
}
`}</code></pre>
    <p>{`Resources imported this way have some processing applied to them:`}</p>
    <ul>
      <li parentName="ul">{`They will usually be optimised if possible`}</li>
      <li parentName="ul">{`Their URL can change between two builds because a hash will be added to their URL for cache-busting.`}</li>
    </ul>
    <p>{`This system will not always meet your needs. If you need webpack to back-off and let you have full control over your resource,
you'll want to use Static Resources (see below).`}</p>
    <h2 {...{
      "id": "static-resources"
    }}>{`Static resources`}</h2>
    <p>{`Resources located in the `}<a parentName="p" {...{
        "href": "https://www.reworkjs.com/configuration#directoriesresources"
      }}><inlineCode parentName="a">{`resources`}</inlineCode></a>{` directory will be copied as-is at the root of the build directory.`}<br parentName="p"></br>{`
`}{`These resources are not processed by webpack, no modification of any kind is done to them.`}</p>
    <p>{`The directory is `}<inlineCode parentName="p">{`./src/public`}</inlineCode>{` by default but is configurable.`}</p>
    <p>{`In order to access the resource, you can't import its url. Instead, you reference it by URL directly.`}<br parentName="p"></br>{`
`}{`E.g.: if your resource is located in `}<inlineCode parentName="p">{`<resource_folder>/favicon.ico`}</inlineCode>{`, your icon will be available as `}<inlineCode parentName="p">{`/favicon.ico`}</inlineCode></p>
    <p>{`symlinks will be followed when copying the contents of the `}<inlineCode parentName="p">{`resources`}</inlineCode>{` folder, allowing you to reference files stored
in vendor folders such as `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`, `}<inlineCode parentName="p">{`bower_modules`}</inlineCode>{`.`}</p>
    <p>{`N.B.: You should avoid importing files located in `}<inlineCode parentName="p">{`resources`}</inlineCode>{` using Webpack mechanisms (such as the `}<inlineCode parentName="p">{`import`}</inlineCode>{` statement) as
that will cause the resource to be bundled twice. Instead, load that resource using browser loading systems (`}<inlineCode parentName="p">{`<link>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<img>`}</inlineCode>{`, etc...).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      